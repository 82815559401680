var $class="se2--navigation-customer-journey",$name="NavigationCustomerJourney",$path="app/components/NavigationCustomerJourney/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import {
  tabClassName,
  tabsClassName,
} from 'app/components/NavigationBarCustomerJourneyJumpTo/config';
import onNavigationKeyDown from 'app/components/NavigationCustomerJourney/utilities/onNavigationKeyDown';
import onTabArrowClick, { arrowWidth, nextButtonClassName, prevButtonClassName }
  from 'app/components/NavigationCustomerJourney/utilities/onTabArrowClick';
import setInitialTabIndexes from 'app/components/NavigationCustomerJourney/utilities/setInitialTabIndexes';
import toggleArrowsVisibility from 'app/components/utilities/toggleArrowsVisibility';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import { css, delays, directions } from 'configs';

import throttle from 'lodash/throttle';

export default shell.registerComponent($this, ({
  addEventListener, mount, settings,
}) => {
  const scrollToActiveTab = (scrollingElement, activeTab) => {
    if (isMobileBreakpoint()) {
      const scrollValue = activeTab.offsetLeft - arrowWidth;
      if ((scrollValue + activeTab.offsetWidth) >= window.innerWidth) {
        scrollingElement.scroll({ left: settings.direction === directions.rtl ? -(scrollValue) : scrollValue });
      }
    }
  };

  const defineActiveTab = (scrollingElement) => {
    let { pathname } = window.location;
    if (pathname.endsWith('/')) pathname = pathname.slice(0, -1);

    const linkOfActiveTab = scrollingElement.querySelector((`li a[href*="${pathname}"]`));

    if (linkOfActiveTab) {
      const activeTab = linkOfActiveTab.parentElement;
      activeTab.classList.add(css.classNames.active);
      setInitialTabIndexes(scrollingElement, activeTab, tabClassName);
      scrollToActiveTab(scrollingElement, activeTab);
    }
  };

  mount((element) => {
    const scrollingElement = element.querySelector(`.${tabsClassName}`);
    const prevButton = element.querySelector(`.${prevButtonClassName}`);
    const nextButton = element.querySelector(`.${nextButtonClassName}`);

    defineActiveTab(scrollingElement);
    addEventListener(
      scrollingElement,
      'scroll',
      () => toggleArrowsVisibility(
        scrollingElement, prevButton, nextButton,
      ),
    );

    addEventListener(
      prevButton,
      'click',
      (event) => onTabArrowClick(event, scrollingElement, tabClassName),
    );

    addEventListener(
      nextButton,
      'click',
      (event) => onTabArrowClick(event, scrollingElement, tabClassName),
    );

    addEventListener(
      element,
      'keydown',
      (event) => onNavigationKeyDown({
        event,
        nextButton,
        prevButton,
        setInitialTabIndexes,
      }),
    );

    // Buttons initialization
    if (isMobileBreakpoint()) {
      toggleArrowsVisibility(
        scrollingElement, prevButton, nextButton,
      );
    }

    addEventListener(
      window,
      'resize',
      throttle(
        () => toggleArrowsVisibility(
          scrollingElement, prevButton, nextButton,
        ),
        delays.ms300,
      ),
    );
  });
});
